import React, { useEffect,useState } from "react";
import DrawerLayout, { ScoreBoard } from '../../components/layout/DrawerLayout';
import { Autocomplete, Badge, Box, Button, Checkbox, Chip, CircularProgress, Divider, FormControlLabel, IconButton, Radio, RadioGroup, Stack, Switch, TextField, Tooltip, tooltipClasses } from "@mui/material";
import { apiDeleteMCA, apiGetSchemeLaunch, apiMca, apiSaveFunds, apiSaveMCA, apiSchemeLaunch, getDataDictionaryValues, getInvestors, uploadFile } from "../../utils/api/api";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { BASE_URL, DD_TYPE_MCA_DEL_REASON, DD_TYPE_SOURCE_TYPE, SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from "../../utils/constants/constants";
import { useSnackbar } from 'notistack';
import { formatDateTime, toLocalePrice, toLocalePriceNoDecimal } from "../../utils/StringUtil";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { RemoveCircle } from "@mui/icons-material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import DialogLayout from "../../components/layout/DialogLayout";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    border: '1px solid #000',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
  },
}));

const MCADrawer = ({ open, toggleDrawer, investor }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [invCommitment, setInvCommitment] = useState(0.00);
  const [mcaList, setMcaList] = useState([]);
  const mcaObject = {
    "isEdit": true,
    "mcaEnvelopId": null,
    "imRid": null,
    "invRid": investor?.invRid,
    "noOfInstallment": null,
    "installmentAmt": null,
    "mcaAmount": null,
    "mcaStatus": 0,
    "triggerMca": false,
    "mcaFiles": [],
    "isCompletedByAdmin": 1,
    "isFirstMca": mcaList.length === 0 ? 1 : 0,
    "mcaCompleted": false,
    "blockUserUpdate": false,
    "isFileUploading": false,
    "imSignStatus": 0,
    "investorSignStatus": 0,
    "trusteeSignStatus": 0,
    "totalMcaAmount": null,
    "isLoading": false,
    "imSignDatetime": null,
    "invSignDatetime": null,
    "trusteeSignDatetime": null,
    "showSignStatus": false,
    "emailSent": 0,
  }

  const [editMca, setEditMca] = useState(null);
  const [reasonsList, setReasonsList] = useState([]);
  const [selReason, setSelReason] = useState(null);
  const [openMcaDelDialog, setOpenMcaDelDialog] = useState(false);
  const [actionLoader, setActionLoader] = useState(false);
  const userType = process.env.REACT_APP_USER_TYPE;

  const handleReasonChange = (event) => {
    const selectedIndex = parseInt(event.target.value);
    const selectedReason = reasonsList.find((item) => item.ddIndex === selectedIndex);
    setSelReason(selectedReason);
  };

  const handleAddMca = () => {
    setMcaList([...mcaList, mcaObject]);
  }
  const handleRemoveMca = (index) => {
    const newList = [...mcaList];
    newList.splice(index, 1);
    setMcaList(newList);
  }

  const toggleMcaDelDialog = (imRid) => {
    if(openMcaDelDialog){
      setEditMca(null);
      setOpenMcaDelDialog(false);
    } else {
      setEditMca(imRid);
      setOpenMcaDelDialog(true);
    }
  }
  const handleDeleteMca = (mca) => {
    setActionLoader(true);
      apiDeleteMCA({
        "imRid": editMca,
        "reason": selReason?.ddValue,
        "deleteDdIndex": selReason?.ddIndex
      })
        .then((data) => {
          setActionLoader(false);
          enqueueSnackbar(data,SNACKBAR_SUCCESS);
          toggleMcaDelDialog();
          getMcaDetails();
        })
        .catch((error) => {
          setActionLoader(false);
          enqueueSnackbar(error, SNACKBAR_ERROR);
        });
  }

  const handleIsEdit = (index) => {
    const newList = [...mcaList];
    newList[index].isEdit = true;
    setMcaList(newList);
  }

  const handleMcaData = (event, index) => {
    const {name, value} = event.target;
    const newList = [...mcaList];
    if(name === 'triggerMca'){
      newList[index].triggerMca = !newList[index].triggerMca;
    } else if(name === 'mcaStatus'){
      newList[index].mcaStatus = newList[index].mcaStatus ? 0 : 1;
      newList[index].triggerMca = newList[index].mcaStatus ? false : newList[index].triggerMca;
    } else {
      newList[index][name] = value;
    }
    setMcaList(newList);
  }

  const uploadMcaDocument = (e, index) => {
    const newList = [...mcaList];
    newList[index].isFileLoading = true;
    setMcaList(newList);
    uploadFile(e.target.files[0])
      .then((res) => {
        const newList = [...mcaList];
        newList[index].isFileLoading = false;
        newList[index].mcaFiles = [...newList[index].mcaFiles, res.data];
        setMcaList(newList);
      })
      .catch((e) => {
        const newList = [...mcaList];
        newList[index].isFileLoading = false;
        setMcaList(newList);

        enqueueSnackbar(e, SNACKBAR_ERROR);
      });
  }
  const deleteMcaDocument = (index, fileIndex) => {
    const newList = [...mcaList];
    const updatedMcaFiles = [...newList[index].mcaFiles];
    updatedMcaFiles.splice(fileIndex, 1);
    newList[index].mcaFiles = updatedMcaFiles;
    setMcaList(newList);
  }

  const getMcaDetails = () => {
    setIsLoading(true);
    apiMca({invRid: investor?.invRid})
      .then((data) => {
        setIsLoading(false);
        const { commitment, mcaList } = data;
        const updatedMcaList = mcaList.map((item) => ({
          isEdit: false,
          mcaEnvelopId: item?.mcaEnvelopId,
          imRid: item?.imRid,
          invRid: item?.invRid,
          noOfInstallment: item?.noOfInstallment,
          installmentAmt: item?.installmentAmt,
          mcaAmount: item?.mcaAmount,
          mcaStatus: item?.mcaStatus,
          triggerMca: false,
          mcaFiles: item?.mcaFiles?JSON.parse(item.mcaFiles):[],
          isCompletedByAdmin: item?.isCompletedByAdmin,
          isFirstMca: item?.isFirstMca,
          mcaCompleted: item?.mcaStatus === 1 ? true : false,
          blockUserUpdate:
            item?.isCompletedByAdmin
            ? false
            : (
              (item?.mcaStatus === 1 || item?.generatedBy === 1 || item?.investorSignStatus === 1 || item?.mcaEnvelopId )
              ? true
              : false
            ),
          isFileUploading: false,
          imSignStatus: item?.imSignStatus,
          investorSignStatus: item?.investorSignStatus,
          trusteeSignStatus: item?.trusteeSignStatus,
          totalMcaAmount: item?.totalMcaAmount,
          isLoading: false,
          imSignDatetime: item?.imSignDatetime,
          invSignDatetime: item?.invSignDatetime,
          trusteeSignDatetime: item?.trusteeSignDatetime,
          showSignStatus: (item?.imSignStatus && item?.investorSignStatus && item?.trusteeSignStatus) ? false : true,
          emailSent: item?.emailSent,
        }));
    
        setMcaList(updatedMcaList);
        setInvCommitment(commitment);
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar(error,SNACKBAR_ERROR)
      });
  }

  const getDataDictionary = () => {
    getDataDictionaryValues()
      .then((data) => {
        const reasons = data.filter((item) =>
          item.ddType === DD_TYPE_MCA_DEL_REASON
        );
        setSelReason(reasons.length > 0 ? reasons[0] : null);
        setReasonsList(reasons);
      })
      .catch((e) => {
        console.log(e);
      })
  }

  useEffect(() => {
    if(open) {
      setMcaList([]);
      setInvCommitment(0.00);
      setSelReason(null);
      getDataDictionary();
      if(investor?.invPref !== 0){
        getMcaDetails();
      }
    }
  }, [open]);

  const handleSaveMca = (index) => {
    const newList = [...mcaList];
    newList[index].isLoading = true;
    setMcaList(newList);

    const mca = mcaList[index];
    apiSaveMCA(mca)
      .then((data) => {
          const newList = [...mcaList];
          newList[index].isLoading = false;
          setMcaList(newList);
          enqueueSnackbar(data,SNACKBAR_SUCCESS);
          getMcaDetails();
      })
      .catch((error) => {
        const newList = [...mcaList];
        newList[index].isLoading = false;
        setMcaList(newList);
        enqueueSnackbar(error,SNACKBAR_ERROR);
      });
  }

  return (
    <DrawerLayout
      open={open}
      title="Master Contribution Agreement"
      subtitle={investor?.fullName}
      drawerWidth="50%"
      closeAction={toggleDrawer}
      positiveAction={
        {label: 'Close', onClick : toggleDrawer}
      }
    >
      <DialogLayout
        open={openMcaDelDialog}
        title="Reason for Deletion"
        maxWidth="sm"
        actionLoader={actionLoader}
        closeAction={toggleMcaDelDialog}
        negativeAction={
          {label: 'Cancel', onClick : toggleMcaDelDialog}
        }
        positiveAction={{label: 'Delete', onClick : handleDeleteMca}}
      >
        <Stack sx={{flex: 1}} direction="column" spacing={1}>
          <h3 className="fs-large-semibold text-danger">Select Reason for MCA Deletion</h3>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={selReason?.ddIndex || ''}
            onChange={handleReasonChange}
          >
            {
              reasonsList.map((item, key) => (
                <FormControlLabel value={item.ddIndex} control={<Radio />} label={item.ddValue} key={key} />
              ))
            }
          </RadioGroup>
        </Stack>
      </DialogLayout>

      { investor?.invPref === 0
      ? <p className="fs-med-regular text-center">
          <i>Please select Investment Preference before proceeding further.</i>
        </p>
      : ( isLoading ? <CircularProgress /> :
      <div className="d-flex gap-3 flex-column">
        <div className="d-flex align-items-center">
          <div className="section-label me-2">Total Commitment:</div>
          <div className="fs-large-medium">
            {toLocalePriceNoDecimal(
              parseFloat(invCommitment) + mcaList
              .filter(item => (item.mcaStatus === 0 && item.imRid !== null))
              .reduce((total, item) => total + parseFloat(item.totalMcaAmount), 0)
            )}
          </div>
        </div>

        {
          mcaList.map((data, key) => (
            <div className="d-flex gap-1" key={key}>
              <div className="d-flex flex-grow-1 card p-3">
                <Stack direction="column" spacing={2}>
                {data.mcaStatus !== 1 && (data.isEdit) && (
                  <div className="d-flex gap-4 align-items-center">
                    {
                      data.emailSent
                      ? <span className="fs-med-medium text-success">Mail Sent</span>
                      : <FormControlLabel
                          control={<Checkbox name="triggerMca" size="small" checked={data.triggerMca} onChange={(e) => handleMcaData(e, key)} disabled={data.mcaCompleted} />}
                          label={<span className="fs-med-medium">Send MCA Email</span>}
                        />
                    }
                    {
                      data.emailSent
                      ? <FormControlLabel
                          control={<Checkbox name="triggerMca" size="small" checked={data.triggerMca} onChange={(e) => handleMcaData(e, key)} disabled={data.mcaCompleted} />}
                          label={<span className="fs-med-medium">Resend</span>}
                        />
                      : ''
                    }
                  </div>
                )}
                {/* { data.mcaStatus === 1 ? '' :
                (data.isEdit && !data.blockUserUpdate ? <Stack direction="row" spacing={2}>
                  <FormControlLabel control={<Checkbox name="triggerMca" size="small" checked={data.triggerMca} onChange={(e) => handleMcaData(e, key)} disabled={data.blockUserUpdate} />} label={<span className="fs-med-medium">Send MCA Email</span>} />
                </Stack> : '') } */}

                  <Stack sx={{alignItems: 'center'}} direction="row" spacing={2}>
                    <Box sx={{ flex: 1 }}>
                      <p className="section-label mb-1" style={{fontSize: '12px'}}>Investment Preference</p>
                      <p className="fs-med-medium p-0 m-0">
                        {
                          investor?.invPref === 1
                          ? 'Lumpsum Investment'
                          : investor?.invPref === 2
                          ? 'SIP Investment'
                          : '-'
                        }
                      </p>
                    </Box>
                                
                    {
                      investor?.invPref === 1
                      ? <Stack sx={{ flex: 1 }} direction="row" spacing={2}>
                          {
                            data.isEdit && !data.blockUserUpdate
                            ? <TextField
                                sx={{ flex: 1 }}
                                id="outlined-basic"
                                name="mcaAmount"
                                label="Lumpsum Amount"
                                variant="outlined"
                                fullWidth
                                value={data.mcaAmount ? data.mcaAmount : ''}
                                onChange={(e) => handleMcaData(e, key)}
                                disabled={data.blockUserUpdate}
                                size="small"
                              />
                            : <Box sx={{ flex: 1 }}>
                                <p className="section-label mb-1" style={{fontSize: '12px'}}>Lumpsum Amount</p>
                                <p className="fs-med-medium p-0 m-0">{toLocalePriceNoDecimal(data.mcaAmount)}</p>
                              </Box>
                          }
                        </Stack>
                      : investor?.invPref === 2
                      ? (
                          data.isFirstMca === 0
                          ? <Stack sx={{ flex: 1 }} direction="row" spacing={2}>
                              {
                                data.isEdit && !data.blockUserUpdate
                                ? <TextField
                                    sx={{ flex: 1 }}
                                    id="outlined-basic"
                                    name="mcaAmount"
                                    label="MCA Amount"
                                    variant="outlined"
                                    fullWidth
                                    value={data.mcaAmount ? data.mcaAmount : ''}
                                    onChange={(e) => handleMcaData(e, key)}
                                    disabled={data.blockUserUpdate}
                                    size="small"
                                  />
                                : <Box sx={{ flex: 1 }}>
                                    <p className="section-label mb-1" style={{fontSize: '12px'}}>MCA Amount</p>
                                    <p className="fs-med-medium p-0 m-0">{toLocalePriceNoDecimal(data.mcaAmount)}</p>
                                  </Box>
                              }
                            </Stack>
                          : <Stack sx={{ flex: 1 }} direction="row" spacing={2}>
                              {
                                data.isEdit && !data.blockUserUpdate
                                ? <TextField
                                    sx={{ flex: 1 }}
                                    id="outlined-basic"
                                    name="installmentAmt"
                                    label="Installment Amount"
                                    variant="outlined"
                                    fullWidth
                                    value={data.installmentAmt ? data.installmentAmt : ''}
                                    onChange={(e) => handleMcaData(e, key)}
                                    disabled={data.blockUserUpdate}
                                    size="small"
                                  />
                                : <Box sx={{ flex: 1 }}>
                                    <p className="section-label mb-1" style={{fontSize: '12px'}}>Installment Amount</p>
                                    <p className="fs-med-medium p-0 m-0">{toLocalePriceNoDecimal(data.installmentAmt)}</p>
                                  </Box>
                              }
                              {
                                data.isEdit && !data.blockUserUpdate
                                ? <TextField
                                    sx={{ flex: 0.5 }}
                                    id="outlined-basic"
                                    name="noOfInstallment"
                                    label="Months"
                                    variant="outlined"
                                    fullWidth
                                    value={data.noOfInstallment ? data.noOfInstallment : ''}
                                    onChange={(e) => handleMcaData(e, key)}
                                    disabled={data.blockUserUpdate}
                                    size="small"
                                  />
                                : <Box sx={{ flex: 0.5 }}>
                                    <p className="section-label mb-1" style={{fontSize: '12px'}}>Months</p>
                                    <p className="fs-med-medium p-0 m-0">{data.noOfInstallment}</p>
                                  </Box>
                              }
                            </Stack>
                        )
                      : <Box sx={{ flex: 1 }} />
                    }
                  </Stack>

                  <Stack direction="row" spacing={2}>
                    <Box sx={{ flex: 1 }}>
                        <div className="d-flex gap-2 align-items-center">
                          <p className="section-label mb-0" style={{fontSize: '12px'}}>MCA SIGNED?</p>
                          {data.mcaEnvelopId ?
                          <CustomTooltip title={
                            <div className="d-flex gap-0 flex-column w-100">
                              <p className="fs-small-medium text-primary p-0 m-0">MCA Envelop ID:</p>
                              <p className="fs-normal-medium text-dark p-0 m-0">{data.mcaEnvelopId}</p>
                            </div>
                          } placement="right" arrow>
                            <InfoOutlinedIcon sx={{fontSize: '16px'}} />
                          </CustomTooltip> : ''}
                        </div>
                        {
                          data.imSignStatus && data.investorSignStatus && data.trusteeSignStatus
                          ? <p className="fs-med-medium p-0 m-0" onClick={() => {
                              const newList = [...mcaList];
                              newList[key].showSignStatus = !newList[key].showSignStatus;
                              setMcaList(newList);
                            }} style={{cursor: 'pointer'}}>
                              <CheckCircleIcon fontSize="small" color="success" /> <u>Signed</u></p>
                          : (
                            data.isEdit && !data.blockUserUpdate
                            ? <Switch name="mcaStatus" color="secondary" checked={data.mcaStatus} onChange={(e) => handleMcaData(e, key)} disabled={data.mcaCompleted || data.blockUserUpdate} />
                            : (
                              data.mcaCompleted
                              ? <p className="fs-med-medium p-0 m-0">
                                  <CheckCircleIcon fontSize="small" color="success" /> Signed</p>
                              : <p className="fs-med-medium p-0 m-0">
                                  <CancelIcon fontSize="small" color="error" /> Not Signed</p>
                            )
                          )
                        }
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Stack sx={{ flexWrap: 'wrap' }} direction="row">
                        <input
                            className="d-none"
                            id={`input-file-${key}`}
                            type="file"
                            accept=".pdf"
                            onClick={(e) => {
                                e.target.value = null;
                            }}
                            onChange={(e) => {
                                uploadMcaDocument(e, key);
                            }}
                        />
                      </Stack>

                      { data.mcaStatus ?
                      <Stack direction="column" className={(!data?.isEdit && data?.mcaFiles?.length === 0) ? 'd-none' : ''}>
                        <p className="section-label mb-1" style={{fontSize: '12px'}}>
                          {
                            data.isEdit
                            ? 'UPLOAD MCA DOCUMENT ( optional )'
                            : 'MCA DOCUMENTS'
                          }
                        </p>
                      <Stack sx={{alignItems: 'center'}} direction="row" spacing={2}>
                          {data.mcaFiles?.map((fileData, fileIndex)=>{
                              return(
                                  <Tooltip title={fileData.actualFileName} key={fileIndex}>
                                    {(
                                      data.isEdit
                                      ? <Badge
                                          badgeContent={<RemoveCircle fontSize="10" onClick={()=>deleteMcaDocument(key, fileIndex)} />}
                                          color="error">
                                          <AttachFileIcon color="secondary" onClick={()=> window.open(`${BASE_URL}/${fileData.path}`, '_blank')} />
                                        </Badge>
                                      : <AttachFileIcon color="secondary" onClick={()=> window.open(`${BASE_URL}/${fileData.path}`, '_blank')} />
                                    )}
                                  </Tooltip>
                              );
                          })}
                          {data.isFileLoading ? <CircularProgress size={20} color="secondary" /> : 
                          (
                            data.isEdit
                            ? <IconButton color="secondary" onClick={() => 
                              document.getElementById(`input-file-${key}`).click()}>
                                <AddIcon />
                              </IconButton>
                            : ''
                          )}
                      </Stack></Stack> : ''}
                    </Box>
                  </Stack>

                  { data.showSignStatus ?
                  <Stack sx={{gap: 3, alignItems: 'center'}} direction="row">
                    {data.imSignStatus ?
                    <div className="d-flex gap-1 align-items-center">
                      <CheckCircleIcon fontSize="small" color="success" />
                      <div className="d-flex flex-column flex-grow-1">
                        <p className="fs-normal-medium p-0 m-0">IM Signed</p>
                        {data.imSignDatetime ?
                        <p className="fs-small-regular p-0 m-0">Signed on {formatDateTime(data.imSignDatetime, false)}</p> : ''}
                      </div>
                    </div> : ''}

                    {data.investorSignStatus ?
                    <div className="d-flex gap-1 align-items-center">
                      <CheckCircleIcon fontSize="small" color="success" />
                      <div className="d-flex flex-column flex-grow-1">
                        <p className="fs-normal-medium p-0 m-0">Investor Signed</p>
                        {data.invSignDatetime ?
                        <p className="fs-small-regular p-0 m-0">Signed on {formatDateTime(data.invSignDatetime, false)}</p> : ''}
                      </div>
                    </div> : ''}

                    {data.trusteeSignStatus ?
                    <div className="d-flex gap-1 align-items-center">
                      <CheckCircleIcon fontSize="small" color="success" />
                      <div className="d-flex flex-column flex-grow-1">
                        <p className="fs-normal-medium p-0 m-0">Trustee Signed</p>
                        {data.trusteeSignDatetime ?
                        <p className="fs-small-regular p-0 m-0">Signed on {formatDateTime(data.trusteeSignDatetime, false)}</p> : ''}
                      </div>
                    </div> : ''}
                  </Stack> : '' }
                </Stack>
              </div>
              { userType === 'ADMIN' && 
              <div className="d-flex flex-column">
                {/* {
                  data.mcaEnvelopId && (!data.imSignStatus || !data.investorSignStatus || !data.trusteeSignStatus)
                  ? ''
                  : (
                    data.isEdit
                    ? <IconButton color="success" onClick={() => handleSaveMca(key)} disabled={data.isLoading || data.isFileLoading}>
                        <DoneIcon />
                      </IconButton>
                    : <IconButton color="primary" onClick={() => handleIsEdit(key)}>
                        <EditIcon />
                      </IconButton>
                  )
                } */}
                {
                  data.isEdit
                  ? <IconButton color="success" onClick={() => handleSaveMca(key)} disabled={data.isLoading || data.isFileLoading}>
                      <DoneIcon />
                    </IconButton>
                  : <IconButton color="primary" onClick={() => handleIsEdit(key)}>
                      <EditIcon />
                    </IconButton>
                }

                {
                  data.imRid
                  ? (
                      data.mcaCompleted ? '' :
                      <IconButton color="error" onClick={() => toggleMcaDelDialog(data.imRid)}>
                        <DeleteIcon />
                      </IconButton>
                    )
                  : <IconButton color="error" onClick={() => handleRemoveMca(key)}>
                      <CloseIcon />
                    </IconButton>
                }
              </div>
        }
            </div>
          ))
        }
      {userType === 'ADMIN' && 
        <div className="d-flex justify-content-start">
          <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddMca}>Add</Button>
        </div>
        }
      </div>) }
    </DrawerLayout>
  );
}

export default MCADrawer;