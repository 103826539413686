import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { DatePicker } from "@mui/x-date-pickers";
import styles from "./Project.module.css";
import {
  Autocomplete,
  Chip,
  CircularProgress,
  IconButton,
  MenuItem,
  Switch,
} from "@mui/material";
import {
  getCoInvestors,
  getCompanies,
  getDataDictionaryValues,
  getMasterClass,
  getOppCoInvestors,
  getPartners,
  opportunityEdit,
  saveOpportunity,
  uploadFile,
  uploadImage,
} from "../../utils/api/api";
import {
  BASE_URL,
  DD_TYPE_PROJECT_DOMAIN,
  DD_TYPE_STAGE_OF_COMPANY,
  DD_TYPE_INVESTMENT_TYPE,
  DD_TYPE_INV_ROUND_TYPE,
  DD_TYPE_OPPORTUNITY_STATUS,
  SNACKBAR_WARNING,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  SNACKBAR_INFO,
} from "../../utils/constants/constants";
import { RemoveCircle } from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  isEitherStringOrNumberValid,
  isTextEmpty,
  isNonZeroNumber,
} from "../../utils/Validator";
import { useSnackbar } from "notistack";
import DrawerLayout, { ScoreBoard } from "../../components/layout/DrawerLayout";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  maxHeight: "80vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "auto",
  padding: "0px 20px",
};

const ProjectModal = ({ open, onClose, isEdit, project, handleSave }) => {
  const [projectForm, setProjectForm] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [stageOfCompanyList, setStageOfCompanyList] = useState([]);
  const [typeOfInvList, setTypeOfInvList] = useState([]);
  const [domainList, setDomainList] = useState([]);
  const [invRoundList, setInvRoundList] = useState([]);
  const [coInvestorsList, setCoInvestorsList] = useState([]);
  const [oppStatusList, setOppStatusList] = useState([]);
  const [thesis, setThesis] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [roundSizeError, setRoundSizeError] = useState(false);
  const [upfrontError, setUpfrontError] = useState(false);
  const [minAmountError, setMinAmountError] = useState(false);
  const [valuationError, setValuationError] = useState(false);
  const [domainError, setDomainError] = useState(false);
  const [typeOfInvError, setTypeOfInvError] = useState(false);
  const [existCommitError, setExistCommitError] = useState(false);
  const [stageOfCompError, setStageOfCompError] = useState(false);
  const [invRoundError, setInvRoundError] = useState(false);
  const [compError, setCompError] = useState(false);
  const [projectDescError, setProjectDescError] = useState(false);
  const [coInvNameError, setCoInvNameError] = useState(false);
  const [coInvDesError, setCoInvDesError] = useState(false);
  const [oppStatusError, setOppStatusError] = useState(false);
  const [masterClassError, setMasterClassError] = useState(false);
  const [carry, setCarry] = useState(0.0);
  const [hurdle, setHurdle] = useState(0.0);
  const [closureDate, setClosureDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [coInvestor, setCoInvestor] = useState({
    invRid: null,
    imgUrl: "",
    description: "",
    fullName: "",
    index: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [masterClassList, setMasterClassList] = useState([]);
  const [partnerRid, setPartnerRid] = useState(null);
  const [partnerList, setPartnerList] = useState([]);

  // data edit functions

  const handleReceivedDate = (selectedDate) => {
    const date = new Date(selectedDate?.$d);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;
    setClosureDate(formattedDate);
  };

  const handleChange = (event) => {
    clearError();

    if (event.target.name === "cmRid") {
      setProjectForm({
        ...projectForm,
        [event.target.name]: event.target.value,
      });
      const masterClass =
        masterClassList.find((option) => option.cmRid === event.target.value) ||
        null;
      if (masterClass) {
        setCarry(masterClass.carry);
        setHurdle(masterClass.hurdleRate);
      }
      return;
    }

    if (event.target.name === "oppStatusIndex") {
      setProjectForm({
        ...projectForm,
        [event.target.name]: event.target.value,
      });

      return;
    }

    if (event.target.type === "number") {
      setProjectForm({
        ...projectForm,
        [event.target.name]: Number(event.target.value),
      });
      return;
    }

    const value = event.target.value.trim();

    setProjectForm({
      ...projectForm,
      [event.target.name]: value,
    });
  };

  const onThesisUpdate = (e) => {
    setThesis(e.target.value);

    //TODO : handle ctrl + enter
  };

  const getPartnersList = () => {
    setPartnerList([]);
    getPartners()
    .then((res) => {
        setPartnerList(res.partnerMasterList);
    })
    .catch((e) => {
        enqueueSnackbar(e, SNACKBAR_ERROR);
    });
}

  const handleAutoComplete = (value, type) => {
    clearError();

    value = value.trim();

    if (Object.keys(projectForm).length === 0 && isEdit) {
      return;
    }

    if (type === DD_TYPE_PROJECT_DOMAIN) {
      const ddData = domainList.find((item) => item.ddValue.trim() === value);

      if (ddData) {
        setProjectForm({
          ...projectForm,
          domain: null,
          domainIndex: ddData.ddIndex,
        });
      } else {
        setProjectForm({
          ...projectForm,
          domain: value,
          domainIndex: null,
        });
      }
    }

    if (type === DD_TYPE_INVESTMENT_TYPE) {
      const ddData = typeOfInvList.find((item) => {
        return item.ddValue.trim() === value;
      });

      if (ddData) {
        setProjectForm({
          ...projectForm,
          typeOfInv: null,
          typeOfInvIndex: ddData.ddIndex,
        });
      } else {
        setProjectForm({
          ...projectForm,
          typeOfInv: value,
          typeOfInvIndex: null,
        });
      }
    }

    if (type === DD_TYPE_STAGE_OF_COMPANY) {
      const ddData = stageOfCompanyList.find(
        (item) => item.ddValue.trim() === value
      );

      if (ddData) {
        setProjectForm({
          ...projectForm,
          stageOfCompany: null,
          stageOfCompanyIndex: ddData.ddIndex,
        });
      } else {
        setProjectForm({
          ...projectForm,
          stageOfCompany: value,
          stageOfCompanyIndex: null,
        });
      }
    }

    if (type === DD_TYPE_INV_ROUND_TYPE) {
      const ddData = invRoundList.find((item) => item.ddValue.trim() === value);

      if (ddData) {
        setProjectForm({
          ...projectForm,
          invRound: null,
          invRoundIndex: ddData.ddIndex,
        });
      } else {
        setProjectForm({
          ...projectForm,
          invRound: value,
          invRoundIndex: null,
        });
      }
    }
  };

  const handleDeleteThesis = (index) => {
    const thesisList = projectForm.thesis;

    thesisList.splice(index, 1);

    setProjectForm({
      ...projectForm,
      thesis: thesisList,
    });
  };

  const handleThesisEdit = (e, index) => {
    if (e.target.value === "") {
      return;
    }

    let thesisList = [];

    if (projectForm.thesis) {
      thesisList = projectForm.thesis;
    }

    thesisList[index] = e.target.value;

    setProjectForm({
      ...projectForm,
      thesis: [...thesisList],
    });
  };

  const handleAddThesis = () => {
    let thesisList = [];

    if (thesis === "") {
      return;
    }

    if (projectForm.thesis) {
      thesisList = projectForm.thesis;
    }

    thesisList.push(thesis);

    setProjectForm({
      ...projectForm,
      thesis: [...thesisList],
    });

    setThesis("");
  };

  const addCoInvestor = () => {
    let hasError = false;
    if (coInvestor.fullName === "") {
      setCoInvNameError(true);
      hasError = true;
    }

    if (coInvestor.description === "") {
      setCoInvDesError(true);
      hasError = true;
    }

    if (hasError) {
      enqueueSnackbar("Please fill in all Details", SNACKBAR_WARNING);
      return;
    }

    let coInvList = [];

    if (projectForm.coInvestorsList) {
      coInvList = projectForm.coInvestorsList;
    }

    if (coInvestor.index === "" || typeof coInvestor.index === "undefined") {
      coInvList.push(coInvestor);
    } else {
      coInvList[coInvestor.index] = coInvestor;
    }

    setProjectForm({
      ...projectForm,
      coInvestorsList: coInvList,
    });

    clearCoInv();
  };

  const handleDocDelete = (index) => {
    const oppFiles = projectForm.oppFiles;

    oppFiles.splice(index, 1);

    setProjectForm({
      ...projectForm,
      oppFiles: oppFiles,
    });
  };

  const handleTermSheetDelete = () => {
    setProjectForm({
      ...projectForm,
      termSheet: null,
    });
  };

  const handleDeleteCoInvestor = (index) => {
    const invList = projectForm.coInvestorsList;

    invList.splice(index, 1);

    setProjectForm({
      ...projectForm,
      coInvestorsList: invList,
    });
  };

  const handleCoInvestor = (e) => {
    clearError();
    setCoInvestor({
      ...coInvestor,
      [e.target.name]: e.target.value,
    });
  };

  const handleCoInvAuto = (value, reason) => {
    clearError();
    if (reason === "reset" || reason === "clear") {
      clearCoInv();
      return;
    }

    const coInv = projectForm?.coInvestorsList?.find(
      (item) => item.invRid === value.invRid
    );

    if (coInv) {
      clearCoInv();
      enqueueSnackbar("This Co-investor is already added", SNACKBAR_INFO);
      return;
    }

    setCoInvestor(value);
  };

  const clearCoInv = () => {
    setCoInvestor({
      invRid: null,
      imgUrl: "",
      description: "",
      fullName: "",
      index: "",
    });
  };

  const handleSwitch = (value) => {
    if (value) {
      setProjectForm({
        ...projectForm,
        isPrivate: 1,
      });
    } else {
      setProjectForm({
        ...projectForm,
        isPrivate: 0,
      });
    }
  };

  const handleCompanySelect = (e) => {
    clearError();
    const value = e.target.value.trim();
    const company = companyList.find((item) => item.title === value);

    if (company) {
      setProjectForm({
        ...projectForm,
        compRid: company.compRid,
      });
    } else {
      setProjectForm({
        ...projectForm,
        compRid: null,
      });
    }
  };

  // const editCoInvestor = (position) => {

  //   let invList = projectForm.coInvestorsList

  //   const coInv = invList.find((_item, index) => index === position)

  //   setCoInvestor({
  //     invRid: coInv.invRid,
  //     fullName: coInv.fullName,
  //     description: coInv.description,
  //     imgUrl: coInv.imgUrl,
  //     index: position
  //   })

  // }

  // api functions

  const getCompanyList = () => {
    getCompanies()
      .then((data) => {
        setCompanyList(data.companyList);
      })
      .catch((error) => {
        showError(error);
      });
  };

  const getDataDictionary = () => {
    getDataDictionaryValues()
      .then((data) => {
        const invTypes = data.filter(
          (item) => item.ddType === DD_TYPE_INVESTMENT_TYPE
        );

        const oppStatus = data.filter(
          (item) => item.ddType === DD_TYPE_OPPORTUNITY_STATUS
        );

        const domains = data.filter(
          (item) => item.ddType === DD_TYPE_PROJECT_DOMAIN
        );

        const stages = data.filter(
          (item) => item.ddType === DD_TYPE_STAGE_OF_COMPANY
        );

        const rounds = data.filter(
          (item) => item.ddType === DD_TYPE_INV_ROUND_TYPE
        );

        // TODO : Adding empty to the list remove after propper fix

        setTypeOfInvList(invTypes);
        setDomainList(domains);
        setStageOfCompanyList(stages);
        setInvRoundList(rounds);
        setOppStatusList(oppStatus);
      })
      .catch((e) => {
        showError(e);
      });
  };

  const getCoInvestorsList = () => {
    getCoInvestors()
      .then((data) => {
        setCoInvestorsList(data);
      })
      .catch((e) => {
        showError(e);
      });
  };

  const getOppInv = (params) => {
    getOppCoInvestors(params)
      .then((data) => {
        setProjectForm({
          ...project,
          coInvestorsList: data,
        });
      })
      .catch((e) => {
        showError(e);
      });
  };

  const addImage = (e) => {
    // sending the mage to get the url

    uploadImage(e.target.files[0])
      .then((res) => {
        // adding the url to the form
        setProjectForm({
          ...projectForm,
          bannerImgPath: res.data.path,
        });
      })
      .catch((error) => {
        showError(error);
      });
  };

  const addProfileImage = (e) => {
    clearError();

    uploadImage(e.target.files[0])
      .then((res) => {
        setCoInvestor({
          ...coInvestor,
          imgUrl: res.data.path,
        });
      })
      .catch((error) => {
        showError(error);
      });
  };

  const addDocuments = (e) => {
    uploadFile(e.target.files[0])
      .then((res) => {
        let projectFileList = [];

        if (projectForm.oppFiles) {
          projectFileList = projectForm.oppFiles;
        }

        projectFileList.push(res.data);

        setProjectForm({
          ...projectForm,
          oppFiles: [...projectFileList],
        });
      })
      .catch((e) => {
        showError(e);
      });
  };

  const addTermSheet = (event) => {
    uploadFile(event.target.files[0])
      .then((res) => {
        setProjectForm({
          ...projectForm,
          termSheet: res.data,
        });
      })
      .catch((e) => {
        showError(e);
      });
  };

  // snackbar functions

  const showSucess = (message) => {
    enqueueSnackbar(message, SNACKBAR_SUCCESS);
  };

  const showError = (message) => {
    enqueueSnackbar(message, SNACKBAR_ERROR);
  };

  // other functions

  const handleClose = () => {
    clearError();
    clearCoInv();
    onClose();
  };

  const clearError = () => {
    setProjectDescError(false);
    setTitleError(false);
    setMinAmountError(false);
    setRoundSizeError(false);
    setUpfrontError(false);
    setValuationError(false);
    setDomainError(false);
    setTypeOfInvError(false);
    setStageOfCompError(false);
    setInvRoundError(false);
    setCompError(false);
    setOppStatusError(false);
    setCoInvDesError(false);
    setCoInvNameError(false);
    setExistCommitError(false);
    setMasterClassError(false);
  };

  const openExlporer = () => {
    document.getElementById("input-image").click();
  };

  const openExlporerForCoInv = () => {
    document.getElementById("input-image-co-inv").click();
  };

  const openDocExplorer = () => {
    document.getElementById("input-file").click();
  };
  const openDocExplorerTermSheet = () => {
    document.getElementById("input-file-term-sheet").click();
  };

  const onSubmit = () => {
    let hasError = false;

    if (isTextEmpty(projectForm.oppTitle)) {
      setTitleError(true);
      hasError = true;
    }

    if (isNonZeroNumber(projectForm.compRid)) {
      setCompError(true);
      hasError = true;
    }

    // if (isTextEmpty(projectForm.shortDesc)) {
    //   setProjectDescError(true)
    //   hasError = true
    // }

    if (isNonZeroNumber(projectForm.roundSize)) {
      setRoundSizeError(true);
      hasError = true;
    }

    if (parseFloat(projectForm.upfront) < 0) {
      setUpfrontError(true);
      hasError = true;
    }

    if (isNonZeroNumber(projectForm.minAmount)) {
      setMinAmountError(true);
      hasError = true;
    }

    if (isNonZeroNumber(projectForm.valuation)) {
      setValuationError(true);
      hasError = true;
    }

    if (isNonZeroNumber(projectForm.existCommit)) {
      setExistCommitError(true);
      hasError = true;
    }

    if (
      isEitherStringOrNumberValid(projectForm.domain, projectForm.domainIndex)
    ) {
      setDomainError(true);
      hasError = true;
    }

    if (
      isEitherStringOrNumberValid(
        projectForm.typeOfInv,
        projectForm.typeOfInvIndex
      )
    ) {
      setTypeOfInvError(true);
      hasError = true;
    }

    if (
      isEitherStringOrNumberValid(
        projectForm.stageOfCompany,
        projectForm.stageOfCompanyIndex
      )
    ) {
      setStageOfCompError(true);
      hasError = true;
    }

    if (
      isEitherStringOrNumberValid(
        projectForm.invRound,
        projectForm.invRoundIndex
      )
    ) {
      setInvRoundError(true);
      hasError = true;
    }

    if (isNonZeroNumber(projectForm.oppStatusIndex)) {
      setOppStatusError(true);
      hasError = true;
    }

    if (isNonZeroNumber(projectForm.cmRid)) {
      setMasterClassError(true);
      hasError = true;
    }

    if (hasError) {
      enqueueSnackbar("Please fill in the required fields", SNACKBAR_WARNING);
      return;
    }

    submitProjectForm();
    clearCoInv();
  };

  const submitProjectForm = () => {
    projectForm.fundingEndDate = closureDate;
    projectForm.partnerRid = partnerRid;
    setIsLoading(true);
    if (isEdit) {
      opportunityEdit(projectForm)
        .then((res) => {
          setIsLoading(false);
          showSucess(res);
          handleSave();
          setThesis("");
        })
        .catch((e) => {
          setIsLoading(false);
          showError(e);
        });
    } else {
      saveOpportunity(projectForm)
        .then((res) => {
          setIsLoading(false);
          showSucess(res);
          handleSave();
          setThesis("");
        })
        .catch((e) => {
          setIsLoading(false);
          showError(e);
        });
    }
  };

  const getMasterClassList = (param = {}) => {
    param.status = 1;
    getMasterClass(param)
      .then((data) => {
        setMasterClassList(data.classMasters);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getPartnersList();
    setCarry(0.0);
    setHurdle(0.0);
    getCompanyList();
    getDataDictionary();
    getCoInvestorsList();
    getMasterClassList();
    if (isEdit) {
      setClosureDate(project.fundingEndDate ?? closureDate);
      if (typeof project.oppFiles === "string") {
        project.oppFiles = JSON.parse(project.oppFiles);
      }
      if (typeof project.oppFiles === "string") {
        project.oppFiles = JSON.parse(project.oppFiles);
      }
      if (typeof project.thesis === "string") {
        project.thesis = JSON.parse(project.thesis);
      }
      if (typeof project.thesis === "string") {
        project.thesis = JSON.parse(project.thesis);
      }

      const masterClass =
        masterClassList.find((option) => option.cmRid === project.cmRid) ||
        null;
      if (masterClass) {
        setCarry(masterClass.carry);
        setHurdle(masterClass.hurdleRate);
      }

      setProjectForm(project);
      if (!isTextEmpty(project.coInvestors)) {
        getOppInv(project.coInvestors);
      }
    } else {
      setProjectForm({});
    }
    setPartnerRid(project?.partnerRid);
  }, [project]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <Modal
        className="overflow-scroll"
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="mt-5" sx={style}>
          <div className="modal-header">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {isEdit ? "Edit Scheme" : "Add Scheme"}
            </Typography>
          </div>

          <Stack
            sx={{ marginBottom: "1rem", marginTop: "2rem" }}
            direction="row"
            spacing={2}
          >
            <TextField
              sx={{ flex: 1 }}
              id="outlined-basic"
              name="oppTitle"
              error={titleError}
              defaultValue={project ? project.oppTitle : ""}
              label="Scheme Title"
              variant="outlined"
              fullWidth
              helperText={titleError === true ? "Not a Valid title" : ""}
              onChange={(e) => handleChange(e)}
            />
            <Autocomplete
            sx={{ flex: 1 }}
            id="outlined-basic"
            variant="outlined"
            options={partnerList}
            getOptionLabel={(option) => option.fullName}
            value={
              partnerList.find((option) => option.partnerRid === partnerRid) ||
              null
            }
            onChange={(e, v) => {
              setPartnerRid(v.partnerRid);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Partner (optional)" />
            )}
          />
            <TextField
              sx={{ flex: 1 }}
              select
              defaultValue={project ? project.oppStatusIndex : ""}
              label="Scheme Status"
              error={oppStatusError}
              name="oppStatusIndex"
              helperText={oppStatusError ? "This field is Required" : ""}
              onChange={(e) => handleChange(e)}
              disabled={
                project
                  ? project.fundTransferred && project.oppStatusIndex === 1503
                  : false
              }
            >
              {oppStatusList.map((item, index) => {
                return (
                  <MenuItem value={item.ddIndex} key={index}>
                    {item.ddValue}
                  </MenuItem>
                );
              })}
            </TextField>
          </Stack>
          <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
            <TextField
              sx={{ flex: 1 }}
              id="outlined-basic"
              name="roundSize"
              type="number"
              helperText={roundSizeError ? "Not a Valid Amount" : ""}
              error={roundSizeError}
              label="Round Size"
              variant="outlined"
              defaultValue={project ? project.roundSize : ""}
              fullWidth
              onChange={(e) => handleChange(e)}
            />
            <TextField
              sx={{ flex: 1 }}
              id="outlined-basic"
              name="minAmount"
              type="number"
              helperText={minAmountError ? "Not a Valid Amount" : ""}
              error={minAmountError}
              label="Minimum Amount"
              variant="outlined"
              defaultValue={project ? project.minAmount : ""}
              fullWidth
              onChange={(e) => handleChange(e)}
            />
            <TextField
              sx={{ flex: 1 }}
              id="outlined-basic"
              name="valuation"
              helperText={valuationError ? "This field is Required" : ""}
              type="number"
              error={valuationError}
              label="Valuation"
              variant="outlined"
              defaultValue={project ? project.valuation : ""}
              fullWidth
              onChange={(e) => handleChange(e)}
            />
          </Stack>
          <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
            <Autocomplete
              freeSolo
              name="domainIndex"
              sx={{ flex: 1 }}
              defaultValue={domainList?.find(
                (item) => item.ddIndex === project?.domainIndex
              )}
              onInputChange={(_event, newInputValue) => {
                handleAutoComplete(newInputValue, DD_TYPE_PROJECT_DOMAIN);
              }}
              options={domainList}
              getOptionLabel={(option) => option.ddValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={domainError}
                  helperText={domainError ? "This field is Required" : ""}
                  label="Domain"
                />
              )}
            />
            <Autocomplete
              freeSolo
              name="typeOfInvIndex"
              sx={{ flex: 1 }}
              defaultValue={typeOfInvList?.find(
                (item) => item.ddIndex === project?.typeOfInvIndex
              )}
              onInputChange={(_event, newInputValue) => {
                handleAutoComplete(newInputValue, DD_TYPE_INVESTMENT_TYPE);
              }}
              options={typeOfInvList}
              getOptionLabel={(option) => option.ddValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={typeOfInvError}
                  helperText={typeOfInvError ? "This field is Required" : ""}
                  label="Type of Investment"
                />
              )}
            />
            <Autocomplete
              freeSolo
              name="stageOfCompanyIndex"
              label="Stage of Company"
              sx={{ flex: 1 }}
              defaultValue={stageOfCompanyList?.find(
                (item) => item.ddIndex === project?.stageOfCompanyIndex
              )}
              onInputChange={(_event, newInputValue) => {
                handleAutoComplete(newInputValue, DD_TYPE_STAGE_OF_COMPANY);
              }}
              options={stageOfCompanyList}
              getOptionLabel={(option) => option.ddValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={stageOfCompError ? "This field is Required" : ""}
                  error={stageOfCompError}
                  label="Stage of Company"
                />
              )}
            />
          </Stack>
          <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
            <TextField
              sx={{ flex: 1 }}
              id="outlined-basic"
              name="existCommit"
              type="number"
              error={existCommitError}
              label="Existing Commitment"
              variant="outlined"
              helperText={existCommitError ? "This field is required" : ""}
              defaultValue={project ? project.existCommit : ""}
              fullWidth
              onChange={(e) => handleChange(e)}
            />
            <Autocomplete
              freeSolo
              name="invRoundIndex"
              label="Current Investment Round"
              sx={{ flex: 1 }}
              defaultValue={invRoundList?.find(
                (item) => item.ddIndex === project?.invRoundIndex
              )}
              onInputChange={(_event, newInputValue) => {
                handleAutoComplete(newInputValue, DD_TYPE_INV_ROUND_TYPE);
              }}
              options={invRoundList}
              getOptionLabel={(option) => option.ddValue}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={invRoundError}
                  helperText={invRoundError ? "This field is Required" : ""}
                  label="Investment Round"
                />
              )}
            />
            <Autocomplete
              freeSolo
              name="company"
              sx={{ flex: 1 }}
              defaultValue={companyList?.find(
                (item) => item.compRid === project?.compRid
              )}
              onSelect={(e) => handleCompanySelect(e)}
              options={companyList}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={compError}
                  helperText={compError ? "Select a existing company" : ""}
                  label="Company"
                />
              )}
            />
          </Stack>
          <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
            <TextField
              sx={{ flex: 1 }}
              id="outlined-basic"
              name="upfront"
              type="number"
              helperText={upfrontError ? "This field is Required" : ""}
              error={upfrontError}
              label="Upfront (%)"
              variant="outlined"
              defaultValue={project ? project.upfront : ""}
              fullWidth
              onChange={(e) => handleChange(e)}
            />
            <TextField
              sx={{ flex: 1 }}
              select
              defaultValue={project ? project.cmRid : ""}
              label="Default Class"
              error={masterClassError}
              name="cmRid"
              helperText={masterClassError ? "This field is Required" : ""}
              onChange={(e) => handleChange(e)}
            >
              {masterClassList.map((item, index) => {
                return (
                  <MenuItem value={item.cmRid} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </TextField>
            <Stack sx={{ flex: 1 }}>
              <DatePicker
                id="outlined-basic"
                name="receivedDate"
                label="Closure Date"
                variant="outlined"
                value={closureDate}
                fullWidth
                onChange={(v) => handleReceivedDate(v)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
            {/* <Stack sx={{ flex: 1 }} direction="row" spacing={2}>
              <ScoreBoard
                label="Carry"
                value={carry}
                flex={1}
              />
              <ScoreBoard
                label="Hurdle"
                value={hurdle}
                flex={1}
              />
            </Stack> */}
          </Stack>
          {/* <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
            <TextField
              rows="4"
              multiline
              error={projectDescError}
              helperText={projectDescError ? "Please Enter Opportunity Description" : ""}
              className="form-control"
              name="shortDesc"
              label="Scheme Description"
              onChange={(e) => handleChange(e)}
              defaultValue={project ? project.shortDesc : ""}
              aria-label="With textarea" />
          </Stack> */}
          <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
            <TextField
              rows="4"
              multiline
              className="form-control"
              name="offerDetails"
              label="Offer Details"
              onChange={(e) => handleChange(e)}
              defaultValue={project ? project.offerDetails : ""}
              aria-label="With textarea"
            />
          </Stack>
          <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
            <TextField
              rows="4"
              multiline
              label="Past Rounds"
              className="form-control"
              name="pastRounds"
              onChange={(e) => handleChange(e)}
              defaultValue={project ? project.pastRounds : ""}
              aria-label="With textarea"
            />
          </Stack>
          <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
            <TextField
              rows="4"
              multiline
              label="Due Dilligence"
              defaultValue={project ? project.dueDilligence : ""}
              className="form-control"
              name="dueDilligence"
              onChange={(e) => handleChange(e)}
              aria-label="With textarea"
            />
          </Stack>

          <p className="section-label mt-4 mb-2">Sprint Thesis</p>

          <div className="row mx-0 align-items-end">
            <div className="col ps-0">
              <textarea
                rows="2"
                className="form-control"
                name="thesis"
                value={thesis}
                onChange={(e) => onThesisUpdate(e)}
                aria-label="With textarea"
              />
            </div>
            <button
              className="btn btn-primary w-fit"
              onClick={() => handleAddThesis()}
            >
              Add
            </button>
          </div>

          {projectForm?.thesis?.map((item, index) => {
            return (
              <Stack
                key={index}
                direction="row"
                spacing={2}
                sx={{ mt: 2, flex: 1 }}
              >
                <textarea
                  rows="2"
                  className="form-control"
                  name="roundOffer"
                  onChange={(e) => handleThesisEdit(e, index)}
                  value={item}
                  aria-label="With textarea"
                ></textarea>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDeleteThesis(index)}
                >
                  <DeleteOutlineIcon className="text-danger" />
                </IconButton>
              </Stack>
            );
          })}

          <p className="section-label mt-4 mb-2">Co Investors</p>
          <div className="d-flex flex-row">
            <div className="d-flex flex-column col ps-0">
              <Autocomplete
                className="mt-2"
                freeSolo
                value={coInvestor}
                sx={{ flex: 1 }}
                onChange={(_event, newInputValue, reason) => {
                  handleCoInvAuto(newInputValue, reason);
                }}
                options={coInvestorsList}
                getOptionLabel={(option) => option.fullName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="fullName"
                    error={coInvNameError}
                    value={coInvestor?.fullName}
                    onChange={(event) => {
                      handleCoInvestor(event);
                    }}
                    helperText={coInvNameError ? "this Field is required" : ""}
                    label="Full Name"
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option.invRid}>
                    {option.fullName}
                  </li>
                )}
              />

              <TextField
                sx={{ flex: 1 }}
                id="outlined-basic"
                name="description"
                label="Description"
                className="mt-4"
                helperText={coInvDesError ? "this Field is required" : ""}
                error={coInvDesError}
                InputLabelProps={{ shrink: true }}
                required
                value={coInvestor?.description}
                onChange={(event) => {
                  handleCoInvestor(event);
                }}
                variant="outlined"
                fullWidth
              />
            </div>
            <div className="ms-3 d-flex flex-column col-auto ps-3">
              <p className="section-label mb-0">Profile</p>

              <input
                id="input-image-co-inv"
                className="d-none"
                type="file"
                onClick={(e) => {
                  e.target.value = null;
                }}
                onChange={(e) => {
                  addProfileImage(e);
                }}
              />
              <img
                className="img-preview"
                onClick={() => openExlporerForCoInv()}
                src={
                  coInvestor.imgUrl === "" || coInvestor.imgUrl === null
                    ? "/no-image.jpg"
                    : `${BASE_URL}/${coInvestor.imgUrl}`
                }
              />
              {/* 
              <button
                onClick={() => clearCoInv()}
                className="btn btn-primary w-fit px-4 mt-2">
                clear
              </button> */}
              <button
                onClick={() => addCoInvestor()}
                className="btn btn-primary w-fit px-4 mt-2"
              >
                Add
              </button>
            </div>
          </div>

          {/* <button
              onClick={() => clearCoInv()}
              className="btn btn-primary w-fit">
              clear
            </button> */}

          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            {projectForm?.coInvestorsList?.map((item, index) => {
              return (
                <Chip
                  key={index}
                  // onClick={() => editCoInvestor(index)}
                  label={item.fullName}
                  onDelete={() => handleDeleteCoInvestor(index)}
                />
              );
            })}
          </Stack>
          <p className="section-label mt-4 mb-2">Scheme Type</p>
          <Stack
            className="project-modal"
            direction="row"
            spacing={3}
            sx={{ mt: 2 }}
          >
            <div className="form-check form-switch">
              <Switch
                sx={{ flex: 0.33 }}
                defaultChecked="false"
                checked={projectForm?.isPrivate ? projectForm.isPrivate : false}
                onChange={(e) => {
                  handleSwitch(e.target.checked);
                }}
              />
              <label className="form-check-label">is Private?</label>
            </div>
          </Stack>

          <p className="section-label mt-4 mb-1">Scheme Image</p>

          <input
            id="input-image"
            type="file"
            onClick={(e) => {
              e.target.value = null;
            }}
            onChange={(e) => {
              addImage(e);
            }}
          />
          <Stack direction="row">
            <img
              className="img-preview"
              onClick={() => openExlporer()}
              src={
                projectForm?.bannerImgPath
                  ? `${BASE_URL}/${projectForm?.bannerImgPath}`
                  : "/no-image.jpg"
              }
            />
          </Stack>

          <p className="section-label mt-4 mb-1">Scheme Documents</p>

          <Stack direction="row">
            <input
              className="d-none"
              id="input-file"
              type="file"
              onClick={(e) => {
                e.target.value = null;
              }}
              onChange={(e) => {
                addDocuments(e);
              }}
            />
          </Stack>
          <Stack direction="row">
            {projectForm?.oppFiles?.map((item, index) => {
              return (
                <Stack direction="column" key={index}>
                  <Stack direction="row" className={styles.docContainer}>
                    <img
                      className={styles.doc}
                      src={process.env.PUBLIC_URL + "/pdf.png"}
                      alt="document"
                    />
                    <RemoveCircle
                      sx={{ color: "red", top: 0, zIndex: 3 }}
                      onClick={() => {
                        handleDocDelete(index);
                      }}
                    />
                  </Stack>
                  <div className={styles.docText}>{item.actualFileName}</div>
                </Stack>
              );
            })}
            <div className="add-project-doc" onClick={() => openDocExplorer()}>
              +
            </div>
          </Stack>

          <p className="section-label mt-4 mb-1">Term Sheet</p>
          <Stack direction="row">
            <input
              className="d-none"
              id="input-file-term-sheet"
              type="file"
              onClick={(e) => {
                e.target.value = null;
              }}
              onChange={(e) => {
                addTermSheet(e);
              }}
            />
          </Stack>
          <Stack direction="row">
            {projectForm?.termSheet === null ||
            typeof projectForm?.termSheet === "undefined" ||
            (typeof projectForm?.termSheet === "object" &&
              Object.keys(projectForm?.termSheet).length === 0) ? (
              <div
                className="add-project-doc"
                onClick={() => openDocExplorerTermSheet()}
              >
                +
              </div>
            ) : (
              <Stack direction="column">
                <Stack direction="row" className={styles.docContainer}>
                  <img
                    className={styles.doc}
                    src={process.env.PUBLIC_URL + "/pdf.png"}
                    alt="document"
                  />
                  <RemoveCircle
                    sx={{ color: "red", top: 0, zIndex: 3 }}
                    onClick={() => {
                      handleTermSheetDelete();
                    }}
                  />
                </Stack>
                <div className={styles.docText}>
                  {typeof projectForm?.termSheet === "object"
                    ? projectForm?.termSheet.actualFileName
                    : JSON.parse(projectForm?.termSheet).actualFileName}
                </div>
              </Stack>
            )}
          </Stack>

          <div className="row mx-0 justify-content-end w-100 mt-3 modal-footer bg-white py-4">
            <button
              className="btn w-fit px-4 me-1"
              onClick={() => handleClose()}
            >
              Cancel
            </button>
            {isLoading ? (
              <div className="btn w-fit px-4">
                <CircularProgress />
              </div>
            ) : (
              <button
                className="btn btn-primary w-fit px-4"
                type="submit"
                onClick={() => onSubmit()}
              >
                Save
              </button>
            )}
          </div>
        </Box>
      </Modal>
    </LocalizationProvider>
  );
};

export default ProjectModal;
