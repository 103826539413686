import React, {useState, useEffect} from 'react';
import DialogLayout from '../../components/layout/DialogLayout';
import { apiFundReceived, apiFundUransferred, apiInvestorOpportunity } from '../../utils/api/api';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from "../../utils/constants/constants";
import { useSnackbar } from 'notistack';
import { CircularProgress, Stack, Switch, TextField } from '@mui/material';
import { useHistory } from "react-router-dom";
import { toLocalePrice, toLocalePriceNoDecimal } from '../../utils/StringUtil';

const FundReceivedDialog = ({ open, toggleDialog, scheme, handleSave }) => {
  const history = useHistory();
  const [invOpportunityList, setInvOpportunityList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [actionLoader, setActionLoader] = useState(false);

    const handleInvOppSwitch = (ioRid) => {
        const updatedList = invOpportunityList.map(item =>
            item.ioRid === ioRid ? { ...item, fundStatus: item.fundStatus ? 0 : 1 } : item
        );
        setInvOpportunityList(updatedList);
    }   

  const getInvestorOpportunity = (param = {}) => {
    setIsLoading(true);
    param.oppRid = scheme.oppRid;
    apiInvestorOpportunity(param)
        .then((data) => {
          setIsLoading(false);
            setInvOpportunityList(data.investorOpportunities);
        })
        .catch((error) => {
          setIsLoading(false);
            enqueueSnackbar(error,SNACKBAR_ERROR);
        });
  }

  useEffect(() => {
    getInvestorOpportunity();
  }, [open]);

    const saveFundReceived = () => {
        const reqBody = {
            "oppRid": scheme.oppRid,
            "investorOpportunities": invOpportunityList.filter(item => item.totalAmount <= item.balanceAmt),
        }
        setActionLoader(true);
        apiFundReceived(reqBody)
            .then((data) => {
              setActionLoader(false);
                enqueueSnackbar(data,SNACKBAR_SUCCESS);
                handleSave();
            })
            .catch((error) => {
              setActionLoader(false);
                enqueueSnackbar(error,SNACKBAR_ERROR);
            });
    }

  return (
    <DialogLayout
      open={open}
      title="Fund Received"
      actionLoader={actionLoader}
      closeAction={toggleDialog}
      negativeAction={
        {label: 'Cancel', onClick : toggleDialog}
      }
      positiveAction={{label: 'Save', onClick : saveFundReceived}}
    >
        <h3 className="fs-large-semibold text-primary mb-4">{scheme.oppTitle}</h3>

        {isLoading ? <CircularProgress /> : 
        <Stack direction="column" spacing={2}>
          <table className="table table-striped table-borderless w-100 p-0 m-0">
            <thead>
              <tr className="fs-normal-semibold text-center">
                <th scope="col">
                  <p className="mb-2">Investor</p>
                </th>
                <th scope="col">
                  <p className="mb-2 text-end">Wallet Amount</p>
                </th>
                <th scope="col">
                  <p className="mb-2 text-end">Total Amount</p>
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              { invOpportunityList.map((data, key) => {
                return(
                  <tr className="fs-normal-medium text-center" key={key}>
                    <td className="pt-3">
                        {data.invFullName}
                        <span className="fs-small-regular text-muted ms-2">{data.invCode}</span>
                    </td>
                    <td className="pt-3 text-end">{toLocalePrice(data.balanceAmt)}</td>
                    <td className="pt-3 text-end">{toLocalePrice(data.totalAmount)}</td>
                    <td className="pt-0 text-center">
                        {
                          !data.fundStatus && (data.balanceAmt < data.totalAmount) ?
                          <div className="fs-small-medium text-danger text-center pt-3">Insufficient Fund</div> :
                          <Switch color="secondary" checked={data.fundStatus} onChange={()=>handleInvOppSwitch(data.ioRid)} disabled={data.fundTransferred} />
                        }
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Stack> }
    </DialogLayout>
  );
}

export default FundReceivedDialog;