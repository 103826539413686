import React, {useState, useEffect} from 'react';
import { Autocomplete, Box, Card, Checkbox, Chip, FormControlLabel, IconButton, Link, Radio, RadioGroup, Stack, Switch, TextField } from '@mui/material';
import DrawerLayout, { ScoreBoard } from '../../components/layout/DrawerLayout';
import { apiSaveInvestorOpportunity, apiUpdateInvestorOpportunity, apiWallet, getInvestors, getMasterClass, uploadFile } from '../../utils/api/api';
import { formatDateTime, toLocalePrice, toLocalePriceNoDecimal } from '../../utils/StringUtil';
import { DEMAT_CHARGES, DRAWDOWN_FAILED, DRAWDOWN_PENDING, DRAWDOWN_SENT, DRAWDOWN_UNSENT, SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from "../../utils/constants/constants";
import { useSnackbar } from 'notistack';
import { containsOnlyNumbers } from '../../utils/Validator';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlaceIcon from '@mui/icons-material/Place';
import DateRangeIcon from '@mui/icons-material/DateRange';
import HomeIcon from '@mui/icons-material/Home';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const SchemeInvestorModal = ({ open, onClose, isEdit, scheme, investor, handleSave }) => {
  const [investorList, setInvestorList] = useState([]);
  const [invRid, setInvRid] = useState(null);
  const [invCode, setInvCode] = useState(null);
  const [walletAmount, setWalletAmount] = useState(0.00);
  const [commitment, setCommitment] = useState(0.00);
  const [upFront, setUpFront] = useState(0);
  const [upFrontAmount, setUpFrontAmount] = useState(0.00);
  const [gst, setGST] = useState(0);
  const [total, setTotal] = useState(0);
  const [masterClassList, setMasterClassList] = useState([]);
  const [cmRid, setCmRid] = useState(null);
  const [carry, setCarry] = useState(0.00);
  const [hurdle, setHurdle] = useState(0.00);
  const [drawdownNoticeStatus, setDrawdownNoticeStatus] = useState(0);
  const [consentRecieved, setConsentRecieved] = useState(false);
  const [unitAllotment, setUnitAllotment] = useState(false);
  const [drawdownNoticeStatusFromServer, setDrawdownNoticeStatusFromServer] = useState(0);
  const [consentRecievedFromServer, setConsentRecievedFromServer] = useState(false);
  const [unitAllotmentFromServer, setUnitAllotmentFromServer] = useState(false);
  const [remark, setRemark] = useState('');
  const [invOppFile, setinvOppFile] = useState({});
  const [ioRid, setIoRid] = useState(null);
  const [fundTransferred, setFundTransferred] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [resendConsent, setResendConsent] = useState(false);
  const [consentDetails, setConsentDetails] = useState({});
  const [actionLoader, setActionLoader] = useState(false);
  const [debit, setDebit] = useState(false);
  const [debitFromServer, setDebitFromServer] = useState(false);
  const [dematCharges, setDematCharges] = useState(0.00);
  const [includeDematCharges, setIncludeDematCharges] = useState(false);
  const handleIncludeDematCharges = () => {
    setDematCharges(includeDematCharges ? 0.00 : DEMAT_CHARGES);
    setIncludeDematCharges(!includeDematCharges);
  }

  const openDocExplorer = () => {
    document.getElementById('input-file').click()
  }

  const uploadDocument = (e) => {
    uploadFile(e.target.files[0])
        .then((res) => {
          setinvOppFile(res.data);
        })
        .catch((e) => {
            enqueueSnackbar(e, SNACKBAR_ERROR);
        });
  }

  const getInvestorList = (param = {}) => {
    param.ckycStatus = 1;
    param.mcaStatus = 1;
    getInvestors(param)
      .then((data) => {
        setInvestorList(data.investorList);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getMasterClassList = (param = {}) => {
    param.status = 1;
    getMasterClass (param)
      .then((data) => {
        setMasterClassList(data.classMasters);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getWallet = (param = {}) => {
    param.invRid = invRid;
    apiWallet(param)
      .then((data) => {
        setWalletAmount(data.balanceAmt);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    const upFrontValue = (parseFloat(commitment) * parseFloat(upFront)) / 100;
    const gstValue = (parseFloat(upFrontValue) * 18.00) / 100;
    const totalValue = parseFloat(commitment) + parseFloat(upFrontValue) + parseFloat(gstValue);
    setUpFrontAmount(upFrontValue);
    setGST(gstValue);
    setTotal(totalValue + dematCharges);
  }, [commitment, upFront, dematCharges]);

  useEffect(() => {
    const masterClass = masterClassList.find((option) => option.cmRid === cmRid) || null;
    if(masterClass){
      setCarry(masterClass.carry);
      setHurdle(masterClass.hurdleRate);
    }
  }, [cmRid]);

  useEffect(() => {
    const inv = investorList.find((option) => option.invRid === invRid) || null;
    if(inv){
      setInvCode(inv.invCode);
      if(!isEdit){
        setUpFront(inv.upfront ?? scheme.upfront);
        setCmRid(inv.cmRid ?? scheme.cmRid);
      }
      getWallet();
    }
  }, [invRid]);

  useEffect(() => {
    getInvestorList();
    getMasterClassList();

    setIoRid(isEdit ? investor.ioRid : null);
    setInvRid(isEdit ? investor.invRid : null);
    setCommitment(isEdit ? investor.commitment : 0.00);
    setUpFrontAmount(isEdit ? upFrontAmount : 0.00);
    setGST(isEdit ? gst : 0.00);
    setTotal(isEdit ? total : 0.00);
    // setCarry(isEdit ? carry : 0.00);
    // setHurdle(isEdit ? hurdle : 0.00);
    setDrawdownNoticeStatus(isEdit ? (investor.drawdown || 0) : 0);
    setConsentRecieved(isEdit ? !!investor.consent && investor.consent === 1 : false);
    setUnitAllotment(isEdit ? !!investor.unitAllotted && investor.unitAllotted === 1 : false);
    setDrawdownNoticeStatusFromServer(isEdit ? (investor.drawdown || 0) : 0);
    setConsentRecievedFromServer(isEdit ? !!investor.consent && investor.consent === 1 : false);
    setUnitAllotmentFromServer(isEdit ? !!investor.unitAllotted && investor.unitAllotted === 1 : false);
    setRemark(isEdit ? (investor.remark || '') : '');
    setinvOppFile(isEdit ? (JSON.parse(investor.invOppFiles) || null) : null);
    setFundTransferred(isEdit ? investor.fundTransferred : 0);
    setResendConsent(false);
    setDebit(isEdit ? investor.debit : false);
    setDebitFromServer(isEdit ? investor.debit : false);
    setDematCharges(isEdit ? (investor.dematCharges ?? 0.00) : 0.00);
    setIncludeDematCharges(isEdit ? (investor.dematCharges > 0.00) : false);
    if(isEdit){
      setUpFront(investor.upfront);
      setCmRid(investor.cmRid);
    } else {
      setInvCode(null);
      setWalletAmount(0.00);
    }
  }, [open]); 

  const saveInvestorOpportunity = () => {
    const reqBody = {
      "ioRid": ioRid,
      "invRid": invRid,
      "oppRid": scheme.oppRid,
      "commitment": parseFloat(commitment),
      "upfront": parseFloat(upFront),
      "cmRid": cmRid,
      "dematCharges": parseFloat(dematCharges),
      "totalAmount": parseFloat(total), 
      // "drawdown": drawdownNoticeStatus,
      "drawdown": consentRecievedFromServer ? 1 : (drawdownNoticeStatus === DRAWDOWN_FAILED || drawdownNoticeStatus === DRAWDOWN_SENT ? (resendConsent ? 0 : drawdownNoticeStatus) : drawdownNoticeStatus),
      "consent": consentRecieved ? 1 : 0,
      "unitAllotted": unitAllotment ? 1 : 0,
      "remark": remark,
      "invOppFiles": invOppFile,
      "debit": debit,
      "status": 1,
    }
    if(isEdit){
      setActionLoader(true);
      apiUpdateInvestorOpportunity(reqBody)
      .then((data) => {
        setActionLoader(false);
          enqueueSnackbar(data,SNACKBAR_SUCCESS);
          handleSave();
      })
      .catch((error) => {
        setActionLoader(false);
        enqueueSnackbar(error,SNACKBAR_ERROR);
      });
    } else {
      setActionLoader(true);
      apiSaveInvestorOpportunity(reqBody)
      .then((data) => {
        setActionLoader(false);
          enqueueSnackbar(data,SNACKBAR_SUCCESS);
          handleSave();
      })
      .catch((error) => {
        setActionLoader(false);
        enqueueSnackbar(error,SNACKBAR_ERROR);
      });
    }
  }

  const onSubmit = () => {
    if(!invRid){
      enqueueSnackbar("Please select investor", SNACKBAR_WARNING);
    } else if(!parseFloat(commitment) > 0){
      enqueueSnackbar("Invalid commitment amount", SNACKBAR_WARNING);
    } else if(upFront === null || parseFloat(upFront) < 0){
      enqueueSnackbar("Invalid upfront % amount", SNACKBAR_WARNING);
    } else if(!cmRid){
      enqueueSnackbar("Please select class", SNACKBAR_WARNING);
    } else {
      saveInvestorOpportunity();
    }
  }

  return (
    <DrawerLayout
      open={open}
      title={isEdit ? investor.invFullName : `Add Investor to ${scheme.oppTitle}`}
      subtitle={isEdit ? investor.invCode : ''}
      actionLoader={actionLoader}
      closeAction={onClose}
      negativeAction={
        {label: 'Cancel', onClick : onClose}
      }
      positiveAction={
        {label: 'Save', onClick : onSubmit}
      }>
      {isEdit ? '' :
      <Stack sx={{ marginBottom: "2rem" }} direction="row" spacing={2}>
        <Autocomplete
          sx={{ flex: 1 }}
          id="outlined-basic"
          name="invRid"
          variant="outlined"
          options={investorList}
          getOptionLabel={(option) => option.fullName}
          value={investorList.find((option) => option.invRid === invRid) || null}
          fullWidth
          onChange={(e, v) => setInvRid(v.invRid)}
          renderInput={(params) => <TextField {...params} label="Select Investor" />}
          renderOption={(props, option) => (
            <div className="d-flex align-items-center" {...props} key={option.invRid}>
              <AccountCircleIcon color="primary" fontSize="large" />
              <div className="flex-grow-1 ms-2">
                <p className="fs-med-medium p-0 m-0">{option.fullName}</p>
                <p className="fs-small-regular text-muted p-0 m-0">{option.invCode}</p>
              </div>
            </div>
            // <li {...props} key={option.invRid}>{option.fullName}</li>
          )}
        />
        <ScoreBoard
            label="Investor Code"
            value={invCode}
            flex={1}
        />
      </Stack> }
      <Stack sx={{ marginBottom: "2rem" }} direction="row" spacing={2}>
          <ScoreBoard
            label="Wallet Amount"
            value={toLocalePrice(walletAmount)}
            flex={1}
          />
          <TextField
              sx={{ flex: 1 }}
              id="outlined-basic"
              name="commitment"
              type="number"
              label="Commitment"
              variant="outlined"
              value={commitment}
              onChange={(e) => setCommitment(e.target.value)}
              fullWidth
              disabled={isEdit}
          />
      </Stack>
      <Stack sx={{ marginBottom: "2rem" }} direction="row" spacing={2}>
          <TextField
              sx={{ flex: 1 }}
              id="outlined-basic"
              name="upFront"
              type="number"
              label="Upfront (%)"
              variant="outlined"
              value={upFront ?? null}
              onChange={(e) => setUpFront(e.target.value)}
              fullWidth
              disabled={isEdit}
          />
          <ScoreBoard
            label="UF Amount"
            value={toLocalePrice(upFrontAmount)}
            flex={1}
          />
          <ScoreBoard
            label="Statutory Fees"
            value={toLocalePrice(gst)}
            flex={1}
          />
          <ScoreBoard
            label="Total"
            value={toLocalePrice(total)}
            flex={1}
          />
          <Box sx={{ flex: 1 }}>
            <p className="section-label mb-1" style={{ fontSize: "12px" }}>Include Demat Charges</p>
            <FormControlLabel required control={<Switch checked={includeDematCharges} onChange={()=>handleIncludeDematCharges()} size="small" />} label={`+ ${toLocalePriceNoDecimal(DEMAT_CHARGES)}`} disabled={isEdit} />
          </Box>
      </Stack>
      <Stack sx={{ marginBottom: "2rem" }} direction="row" spacing={2}>
          <Autocomplete
            sx={{ flex: 1 }}
            id="outlined-basic"
            variant="outlined"
            options={masterClassList}
            getOptionLabel={(option) => option.name}
            value={masterClassList.find((option) => option.cmRid === cmRid) || null}
            fullWidth
            onChange={(e, v) => setCmRid(v.cmRid)}
            renderInput={(params) => <TextField {...params} label="Class" />}
            disabled={isEdit}
          />
          <ScoreBoard
            label="Carry"
            value={carry + "%"}
            flex={1}
          />
          <ScoreBoard
            label="Hurdle"
            value={hurdle + "%"}
            flex={1}
          />
      </Stack>
      <Stack sx={{ marginBottom: "2rem" }} direction="column">
        <p className="section-label mb-2">Send Drawdown Notice</p>

        {drawdownNoticeStatusFromServer === DRAWDOWN_UNSENT && 
          <RadioGroup row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={drawdownNoticeStatus} onChange={(e)=>setDrawdownNoticeStatus(parseInt(e.target.value))}>
            <FormControlLabel value="0" control={<Radio />} label="Trigger" />
            <FormControlLabel value="1" control={<Radio />} label="Mark As Sent"  />
          </RadioGroup> }

        {/* {drawdownNoticeStatusFromServer === DRAWDOWN_SENT && <p className="fs-large-medium p-0 m-0"><CheckCircleIcon color="success" className="me-2" />Sent</p>} */}
        {drawdownNoticeStatusFromServer === DRAWDOWN_SENT && 
        <div className="d-flex align-items-center">
          <p className="fs-large-medium p-0 m-0">
            <CheckCircleIcon color="success" className="me-2" />Sent</p>
            {consentRecievedFromServer ? '' :
            <FormControlLabel control={<Checkbox size="small" checked={resendConsent} onChange={(e)=>setResendConsent(e.target.checked)} />} label="Resend" className="ms-3" disabled={consentRecievedFromServer} /> }
        </div>}

        {drawdownNoticeStatusFromServer === DRAWDOWN_PENDING && <p className="fs-large-medium p-0 m-0"><WarningIcon color="warning" className="me-2" />Pending</p>}

        { drawdownNoticeStatusFromServer === DRAWDOWN_FAILED && 
        <div className="d-flex align-items-center">
          <p className="fs-large-medium p-0 m-0"><ErrorIcon color="error" className="me-2" />Failed</p>
          {consentRecievedFromServer ? '' :
          <FormControlLabel control={<Checkbox size="small" checked={resendConsent} onChange={(e)=>setResendConsent(e.target.checked)} />} label="Resend" className="ms-3" disabled={consentRecievedFromServer} /> }
        </div> }
      </Stack>
      <Stack sx={{ marginBottom: "2rem" }} direction="column">
        <p className="section-label mb-2">Consent Received</p>
        <Stack direction="row" spacing={2} sx={{alignItems: 'start'}}>
            {
              consentRecievedFromServer ?
              <p className="fs-large-medium"><CheckCircleIcon color="success" className="me-2" /> Received</p> :
              <FormControlLabel required control={<Switch checked={consentRecieved} onChange={()=>setConsentRecieved(!consentRecieved)} />} label="Received" />
            }
            { consentRecieved ?
              <TextField
                sx={{ flex: 1 }}
                id="outlined-multiline-static"
                label="Remark"
                multiline
                rows={2}
                value={remark}
                onChange={(e)=>setRemark(e.target.value)}
                disabled={consentRecievedFromServer ? true : false}
                className={consentRecievedFromServer && remark.trim() === ''  ? 'd-none' : ''}
                fullWidth
              />
            : '' }
          </Stack>
      </Stack>
      <Stack sx={{ marginBottom: "2rem" }} direction="column" className={investor?.ipAddress === undefined || investor?.ipAddress === null ? 'd-none' : ''}>
        <p className="section-label mb-2">Consent Details</p>
        <Stack direction="row" spacing={2}>
          <div className="d-flex gap-3 flex-grow-1">
            {investor?.lat !== null && investor?.lon !== null &&
            <Chip
              variant="outlined"
              label={
                <div className="fs-normal-semibold">
                  <span className="fs-normal-regular">LOCATION : </span>
                  <Link>{investor?.lat}, {investor?.lon}</Link>
                </div>
              }
              onClick={()=> window.open(`https://maps.google.com/maps?q=${investor?.lat},${investor?.lon}`, "_blank")}
            /> }
            {investor?.ipAddress !== null &&
            <Chip
            variant="outlined"
              label={
                <div className="fs-normal-semibold">
                  <span className="fs-normal-regular">IP : </span>
                  {investor?.ipAddress}
                </div>
              }
            /> }
            {investor?.consentDate !== null &&
            <Chip
            variant="outlined"
              label={
                <div className="fs-normal-semibold">
                  <span className="fs-normal-regular">DATE & TIME : </span>
                  {formatDateTime(investor?.consentDate, true)}
                </div>
              }
            /> }
          </div>
        </Stack>
      </Stack>
      <Stack sx={{ marginBottom: "2rem" }} direction="column">
        <p className="section-label mb-2">Fund Received</p>
        <Stack direction="row" spacing={2} sx={{alignItems: 'start'}}>
          {
            debitFromServer
            ? <p className="fs-large-medium"><CheckCircleIcon color="success" className="me-2" /> Recieved</p>
            : (
              total > walletAmount ? <p className="fs-large-medium text-danger">Insufficient Fund</p> : 
              <FormControlLabel required control={<Switch checked={debit} onChange={()=>setDebit(!debit)} />} label="Received" />
            )
          }
        </Stack>
      </Stack>
      <Stack sx={{ marginBottom: "2rem" }} direction="column" className={consentRecievedFromServer ? '' : 'd-none'}>
        <p className="section-label mb-2">Unit Allotment</p>
        <Stack direction="row" spacing={2} sx={{alignItems: 'start'}}>
            {
              unitAllotmentFromServer ?
              <p className="fs-large-medium"><CheckCircleIcon color="success" className="me-2" /> Recieved</p> :
              <FormControlLabel required control={<Switch checked={unitAllotment} onChange={()=>setUnitAllotment(!unitAllotment)} />} label="Alloted" />
            }
            
            { unitAllotment ? 
            <div className="d-flex align-items-center">
              <input
                className="d-none"
                id="input-file"
                type="file"
                accept=".pdf"
                onClick={(e) => {
                    e.target.value = null;
                }}
                onChange={(e) => {
                  uploadDocument(e);
                }}
              />
              <IconButton color="error" onClick={() => openDocExplorer()}>
                <AttachFileIcon />
              </IconButton>
              { invOppFile === null || Object.keys(invOppFile).length === 0 ? '' : <div className="fs-large-regular">{invOppFile.actualFileName}</div> }
            </div> : '' }
          </Stack>
      </Stack>
    </DrawerLayout>
  );
}

export default SchemeInvestorModal;