import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  Skeleton,
} from "@mui/material";
import FundReceivedDrawer from "./FundReceivedDrawer";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import {
  DD_TYPE_SOURCE_TYPE,
  RECORD_DISPLAY_LIMIT,
} from "../../utils/constants/constants";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FeeGenerated from "./FeeGenerated";
import ActiveScheme from "./ActiveScheme";
import PaymentHistory from "./PaymentHistory";
import {
  apiFunds,
  getDataDictionaryValues,
  getPartners,
} from "../../utils/api/api";
import { SNACKBAR_ERROR } from "../../utils/constants/constants";
import { isSubAdmin, toLocalePrice, toLocalePriceNoDecimal } from "../../utils/StringUtil";

import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import ChecklistIcon from "@mui/icons-material/Checklist";
import { ScoreBoard } from "../../components/layout/DrawerLayout";
import FundWithdraw from "./FundWithdraw";
import PartnerPayments from "./PartnerPayments";
import InvestorFeeGenerated from "./InvestorFeeGenerated";
import SchemeExpenses from "./SchemeExpenses";

function FundMaster() {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [anchorElMap, setAnchorElMap] = useState({});
  const [anchorElMain, setAnchorElMain] = useState(null);
  const openMainMenu = Boolean(anchorElMain);
  const [openFundWithdraw, setOpenFundWithdraw] = useState(false);
  const [openFeeGenerated, setOpenFeeGenerated] = useState(false);
  const [openInvFeeGenerated, setOpenInvFeeGenerated] = useState(false);
  const [openActiveScheme, setOpenActiveScheme] = useState(false);
  const [openPaymentHistory, setOpenPaymentHistory] = useState(false);
  const [openPartnerPayments, setOpenPartnerPayments] = useState(false);
  const [openSchemeExpenses,setOpenSchemeExpenses]=useState(false);
  const [investorList, setInvestorList] = useState([]);
  const [editInvestor, setEditInvestor] = useState(null);
  const [fundTotalObj, setFundTotalObj] = useState(null);

  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [sourceList, setSourceList] = useState([]);
  const [filterPartner, setFilterPartner] = useState([]);
  const [partnerRid, setPartnerRid] = useState(null);
  const [partnerList, setPartnerList] = useState([]);
  const userType = process.env.REACT_APP_USER_TYPE;

  const handleMenuOpen = (event, index) => {
    setAnchorElMap((prevState) => ({
      ...prevState,
      [index]: event.currentTarget,
    }));
  };
  const handleMenuClose = (index) => {
    setAnchorElMap((prevState) => ({
      ...prevState,
      [index]: null,
    }));
  };

  const handleOpen = () => {
    setOpen(true);
    setEditInvestor(null);
  };

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const handleSave = () => {
    setPage(1);
    getFunds();
    handleClose();
  };

  const handleFundWithdrawSave = () => {
    setPage(1);
    getFunds();
    toggleFundWithdraw();
  };

  const handleActiveSchemeSave = () => {
    setPage(1);
    getFunds();
    toggleActiveScheme();
  };

  const handlePaymentHistorySave = () => {
    setPage(1);
    getFunds();
    // togglePaymentHistory();
  };

  const handleEdit = (index, id) => {
    setEditInvestor(investorList.find((item) => item.invRid === id));
    setOpen(true);
    setIsEdit(true);
    handleMenuClose(index);
  };

  const handlePageChange = (_event, value) => {
    setPage(value);
  };

  const togglePartnerPayments = () => {
    if (openPartnerPayments) {
      setOpenPartnerPayments(false);
    } else {
      setOpenPartnerPayments(true);
      setAnchorElMain(null);
    }
  };

  const toggleSchemeExpenses=()=>{
    if(openSchemeExpenses){
      setOpenSchemeExpenses(false);
    }else{
      setOpenSchemeExpenses(true);
      setAnchorElMain(null);
    }
  }
  const toggleFundWithdraw = (index, id) => {
    setEditInvestor(investorList.find((item) => item.invRid === id));
    setOpenFundWithdraw(!openFundWithdraw);
    handleMenuClose(index);
  };
  const toggleFeeGenerated = (index, id) => {
    if (openFeeGenerated) {
      setOpenFeeGenerated(false);
    } else {
      setOpenFeeGenerated(true);
      setAnchorElMain(null);
    }
    // setEditInvestor(investorList.find((item) => item.invRid === id));
    // setOpenFeeGenerated(!openFeeGenerated);
    // handleMenuClose(index);
  };
  const toggleInvFeeGenerated = (index, id) => {
    if (openInvFeeGenerated) {
      setOpenInvFeeGenerated(false);
    } else {
      setEditInvestor(investorList.find((item) => item.invRid === id));
      setOpenInvFeeGenerated(true);
      handleMenuClose(index);
    }
  };
  const toggleActiveScheme = (index, id) => {
    setEditInvestor(investorList.find((item) => item.invRid === id));
    setOpenActiveScheme(!openActiveScheme);
    handleMenuClose(index);
  };
  const togglePaymentHistory = (index, id) => {
    setEditInvestor(investorList.find((item) => item.invRid === id));
    setOpenPaymentHistory(!openPaymentHistory);
    handleMenuClose(index);
  };

  const getFunds = (param = {}) => {
    setIsLoading(true);
    param.name = searchTerm;
    param.recLimit = RECORD_DISPLAY_LIMIT;
    param.pageNumber = page;
    param.partnerRid = filterPartner.map((item) => item).join(",");
    apiFunds(param)
      .then((data) => {
        setIsLoading(false);
        setInvestorList(data.funds);
        if (page === 1) {
          setTotalRecords(data.records);
          setFundTotalObj(data.fundsTotal);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar(error, SNACKBAR_ERROR);
      });
  };

  useEffect(() => {
    if (searchTerm.length < 3) {
      if (searchTerm.trim() === "") {
        getFunds();
      } else {
        return;
      }
    } else {
      getFunds();
    }
    setPage(1);
  }, [searchTerm]);

  const getPartnersList = () => {
    setPartnerList([]);
    getPartners()
      .then((res) => {
        setPartnerList(res.partnerMasterList);
      })
      .catch((e) => {
        enqueueSnackbar(e, SNACKBAR_ERROR);
      });
  };

  useEffect(() => {
    getFunds();
  }, [page, filterPartner]);

  useEffect(() => {
    getPartnersList();
  }, []);

  return (
    <Layout title="Fund Master">
      {userType === "ADMIN" &&!isSubAdmin() && (
        <Button
          sx={{
            height: "64px",
            borderRadius: "50%",
            position: "fixed",
            bottom: "20px",
            right: "20px",
          }}
          onClick={handleOpen}
          variant="contained"
          aria-label="edit"
          color="secondary"
        >
          <AddIcon />
        </Button>
      )}

      <FundReceivedDrawer
        open={open}
        onClose={handleClose}
        isEdit={isEdit}
        investor={editInvestor}
        handleSave={() => handleSave()}
      />
      <FundWithdraw
        open={openFundWithdraw}
        toggleDrawer={toggleFundWithdraw}
        investor={editInvestor}
        handleSave={() => handleFundWithdrawSave()}
      />
      <InvestorFeeGenerated
        open={openInvFeeGenerated}
        toggleDrawer={toggleInvFeeGenerated}
        investor={editInvestor}
      />
      <FeeGenerated
        open={openFeeGenerated}
        toggleDrawer={toggleFeeGenerated}
        fundTotalObj={fundTotalObj}
      />
      <ActiveScheme
        open={openActiveScheme}
        toggleDrawer={toggleActiveScheme}
        investor={editInvestor}
        handleSave={() => handleActiveSchemeSave()}
      />
      <PaymentHistory
        open={openPaymentHistory}
        toggleDrawer={togglePaymentHistory}
        investor={editInvestor}
        handleSave={() => handlePaymentHistorySave()}
      />
      <PartnerPayments
        open={openPartnerPayments}
        toggleDrawer={togglePartnerPayments}
      />
      <SchemeExpenses
        open={openSchemeExpenses}
        toggleDrawer={toggleSchemeExpenses}
      />

      <div className="p-4">
        <div
          className="d-flex flex-row justify-content-between bg-white p-3 mb-4"
          style={{ borderRadius: "5px" }}
        >
          <div className="d-flex gap-2 align-items-center">
            <div
              className="search-input-btn d-flex flex-row justify-content-between rounded bg-white px-3"
              style={{ border: "1px solid #000" }}
            >
              <div className="my-auto">
                <SearchIcon />
              </div>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-control custom-form-control border-0 bg-transparent fs-large-regular "
                placeholder="Search"
              />
              <div onClick={() => setSearchTerm("")} className="my-auto">
                <CloseIcon sx={{ cursor: "pointer" }} />
              </div>
            </div>
            {userType === "ADMIN" && (
              <FormControl sx={{ width: 180 }} size="small">
                <InputLabel id="demo-multiple-checkbox-label">
                  Filter By Partner
                </InputLabel>
                <Select
                  multiple
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={filterPartner}
                  onChange={(e) => setFilterPartner(e.target.value)}
                  input={<OutlinedInput label="Filter By Partner" />}
                  renderValue={(selected) => `${selected.length} Selected`}
                >
                  {partnerList.map((data, key) => (
                    <MenuItem key={key} value={data.partnerRid}>
                      <Checkbox
                        checked={filterPartner.indexOf(data.partnerRid) > -1}
                      />
                      <ListItemText primary={data.fullName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>

          <div className="d-flex gap-2 align-items-center">
            <Pagination
              color="secondary"
              size="small"
              siblingCount={0}
              onChange={handlePageChange}
              count={
                totalRecords < RECORD_DISPLAY_LIMIT
                  ? 1
                  : Math.ceil(totalRecords / RECORD_DISPLAY_LIMIT)
              }
              page={page}
            />
            {/* <Button sx={{width: 200}} variant="contained" className="ms-3" color="secondary" onClick={toggleFeeGenerated} disableElevation>Fee Generated</Button> */}
            {userType === 'ADMIN' &&
            <IconButton
              color="secondary"
              id="basic-button"
              aria-controls={openMainMenu ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openMainMenu ? "true" : undefined}
              onClick={(e) => setAnchorElMain(e.currentTarget)}
            >
              <MoreVertIcon fontSize="medium" />
            </IconButton>
}
            <Menu
              id="basic-menu"
              anchorEl={anchorElMain}
              open={openMainMenu}
              elevation={3}
              onClose={() => setAnchorElMain(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                className="fs-large-regular"
                onClick={toggleFeeGenerated}
              >
                Fee Generated
              </MenuItem>
              <MenuItem
                className="fs-large-regular"
                onClick={togglePartnerPayments}
              >
                Partner Payments
              </MenuItem>
              {!isSubAdmin() && userType ==='ADMIN' ?
              <MenuItem
              className="fs-large-regular"
              onClick={toggleSchemeExpenses}
            >
              Scheme Expenses
            </MenuItem>:null
            }
              
            </Menu>
          </div>
        </div>

        <div
          className="d-flex gap-4 justify-content-between align-items-center bg-white p-3 mb-4"
          style={{ borderRadius: "5px" }}
        >
          <Box>
            <p className="section-label mb-1" style={{ fontSize: "12px" }}>
              commitments
            </p>
            <p className="fs-med-medium p-0 m-0">
              ₹ {toLocalePriceNoDecimal(fundTotalObj?.mcaAmount)}
            </p>
          </Box>
          <Box>
            <p className="section-label mb-1" style={{ fontSize: "12px" }}>
              Yet to Transfer
            </p>
            <p className="fs-med-medium p-0 m-0">
              ₹ {toLocalePriceNoDecimal(fundTotalObj?.yetToTransfer)}
            </p>
          </Box>
          <Box>
            <p className="section-label mb-1" style={{ fontSize: "12px" }}>
              Unallocated
            </p>
            <p className="fs-med-medium p-0 m-0">
              ₹ {toLocalePriceNoDecimal(fundTotalObj?.unAllocated)}
            </p>
          </Box>
          <Box>
            <p className="section-label mb-1" style={{ fontSize: "12px" }}>
              Undeployed
            </p>
            <p className="fs-med-medium p-0 m-0">
              ₹ {toLocalePriceNoDecimal(fundTotalObj?.unDeployed)}
            </p>
          </Box>
          <Box>
            <p className="section-label mb-1" style={{ fontSize: "12px" }}>
              Deployed
            </p>
            <p className="fs-med-medium p-0 m-0">
              ₹ {toLocalePriceNoDecimal(fundTotalObj?.deployed)}
            </p>
          </Box>
        </div>

        <div className="bg-white py-2 px-3" style={{ borderRadius: "5px" }}>
          {isLoading ? (
            [...Array(RECORD_DISPLAY_LIMIT)].map((_e, index) => (
              <Skeleton
                animation="wave"
                key={index}
                style={{ marginBottom: 6 }}
                variant="rectangle"
                height={40}
              />
            ))
          ) : (
            <div>
              <table
                className="table table-striped table-borderless w-100"
                id="order-table"
              >
                <thead>
                  <tr className="fs-normal-semibold text-center">
                    <th scope="col">
                      <p className="mb-2">Investor</p>
                    </th>
                    <th scope="col">
                      <p className="mb-2 text-end">Commitment Amount</p>
                    </th>
                    <th scope="col">
                      <p className="mb-2 text-end">Yet to Transfer</p>
                    </th>
                    <th scope="col">
                      <p className="mb-2 text-end">Unallocated</p>
                    </th>
                    <th scope="col">
                      <p className="mb-2 text-end">Undeployed</p>
                    </th>
                    <th scope="col">
                      <p className="mb-2 text-end">Deployed</p>
                    </th>
                    <th scope="col">
                      <p className="mb-2 text-center">Actions</p>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-table-blue">
                  {investorList.map((data, key) => {
                    return (
                      <tr className="fs-normal-medium text-center" key={key}>
                        <td className="pt-3">
                          {data.fullName}
                          <span className="fs-small-regular text-muted ms-2">
                            {data.invCode}
                          </span>
                        </td>
                        <td className="pt-3 text-end">
                          {toLocalePriceNoDecimal(data.mcaAmount)}
                        </td>
                        <td className="pt-3 text-end">
                          {toLocalePriceNoDecimal(data.yetToTransfer)}
                        </td>
                        <td className="pt-3 text-end">
                          {toLocalePriceNoDecimal(data.unAllocated)}
                        </td>
                        <td className="pt-3 text-end">
                          {toLocalePriceNoDecimal(data.unDeployed)}
                        </td>
                        <td className="pt-3 text-end">
                          {toLocalePriceNoDecimal(data.deployed)}
                        </td>
                        <td className="pt-0 text-center">
                          <IconButton
                            id="basic-button"
                            aria-controls={
                              Boolean(anchorElMap[key])
                                ? "basic-menu"
                                : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              Boolean(anchorElMap[key]) ? "true" : undefined
                            }
                            onClick={(e) => handleMenuOpen(e, key)}
                          >
                            <MoreVertIcon fontSize="small" />
                          </IconButton>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorElMap[key]}
                            open={Boolean(anchorElMap[key])}
                            elevation={1}
                            onClose={() => handleMenuClose(key)}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            color="secondary"
                          >
                             {!isSubAdmin() && (
                            <MenuItem
                              className="fs-med-regular"
                              onClick={() => handleEdit(key, data.invRid)}
                            >
                              <MoveToInboxIcon
                                className="me-2"
                                fontSize="small"
                              />
                              Fund Received
                            </MenuItem>
                             )}
                            {userType === "ADMIN" && !isSubAdmin() && (
                              <MenuItem
                                className="fs-med-regular"
                                onClick={() =>
                                  toggleFundWithdraw(key, data.invRid)
                                }
                              >
                                <MoveToInboxIcon
                                  className="me-2"
                                  fontSize="small"
                                />
                                Fund Withdraw
                              </MenuItem>
                            )}
                            {userType === "ADMIN" && (
                              <MenuItem
                                className="fs-med-regular"
                                onClick={() =>
                                  togglePaymentHistory(key, data.invRid)
                                }
                              >
                                <ReceiptIcon
                                  className="me-2"
                                  fontSize="small"
                                />
                                Payment History
                              </MenuItem>
                            )}

                            <MenuItem
                              className="fs-med-regular"
                              onClick={() =>
                                toggleInvFeeGenerated(key, data.invRid)
                              }
                            >
                              <ReceiptLongIcon
                                className="me-2"
                                fontSize="small"
                              />
                              Fee Generated
                            </MenuItem>
                            <MenuItem
                              className="fs-med-regular"
                              onClick={() =>
                                toggleActiveScheme(key, data.invRid)
                              }
                            >
                              <ChecklistIcon
                                className="me-2"
                                fontSize="small"
                              />
                              Active Schemes
                            </MenuItem>
                          </Menu>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default FundMaster;
